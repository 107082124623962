import "./i18n/config";
import { type AlertColor, Typography, Tabs, Tab, Button } from "@mui/material";
import { Component } from "react";
import GroupDetails from "./components/GroupDetails";
import GroupDetailsTW from "./components/GroupDetailsTW";
import GroupMembers from "./components/GroupMembers";
import { Empty } from "./components/Empty";
import { isMemberAdmin, type GroupInfo, type GroupMember } from "./types/gigya";
import { GigyaGroupModels } from "./types/gigya.schema";
import "./App.css";
import "./components/.exported_teleporthq/groups wip-react/src/style.css";
import P11440311HGMHomeAdminmemberViewgroupdetailstabclon from "./components/.exported_teleporthq/groups wip-react/src/views/p11440311h-g-m-home-adminmember-viewgroupdetailstabclon";
import { type WithTranslation, withTranslation } from "react-i18next";
import { SelectW, transformExportedDesign } from "./muiConverter";
import React from "react";
import ModalDialog from "./components/ModalDialog";
import { Buffer } from "buffer";
import { withRouter, type WithRouterProps } from "./withRouter";
import { REDIRECT_URLS, EMPTY_MEMBERS } from "./constants";
import { pushDataLayerEvent } from "./utils";
import axios from "axios";

const ENVIRONMENT: string = process.env.REACT_APP_ENVIRONMENT || "dev";

const getRedirectUrl = () => {
  const TST = "tst";
  const PRD = "prd";
  if (process.env.REACT_APP_LOCALE === "zhTW") {
    if (ENVIRONMENT === TST)
      return REDIRECT_URLS.TW.REACT_APP_TST_REDIRECT_URL || "";
    if (ENVIRONMENT === PRD)
      return REDIRECT_URLS.TW.REACT_APP_PRD_REDIRECT_URL || "";
    return REDIRECT_URLS.TW.REACT_APP_DEV_REDIRECT_URL || "";
  }

  if (process.env.REACT_APP_LOCALE === "koKR") {
    if (ENVIRONMENT === TST)
      return REDIRECT_URLS.KR.REACT_APP_TST_REDIRECT_URL || "";
    if (ENVIRONMENT === PRD)
      return REDIRECT_URLS.KR.REACT_APP_PRD_REDIRECT_URL || "";
    return REDIRECT_URLS.KR.REACT_APP_DEV_REDIRECT_URL || "";
  }

  if (process.env.REACT_APP_LOCALE === "jaJP") {
    if (ENVIRONMENT === TST)
      return REDIRECT_URLS.JP.REACT_APP_TST_REDIRECT_URL || "";
    if (ENVIRONMENT === PRD)
      return REDIRECT_URLS.JP.REACT_APP_PRD_REDIRECT_URL || "";
    return REDIRECT_URLS.JP.REACT_APP_DEV_REDIRECT_URL || "";
  }

  if (ENVIRONMENT === TST)
    return REDIRECT_URLS.US.REACT_APP_TST_REDIRECT_URL || "";
  if (ENVIRONMENT === PRD)
    return REDIRECT_URLS.US.REACT_APP_PRD_REDIRECT_URL || "";
  return REDIRECT_URLS.US.REACT_APP_DEV_REDIRECT_URL || "";
};

// THESE SEARCH GROUP types were created because they do not match any of the
// other types generated from the API call. The missing or incorrectly typed
// properties are not critical, so removing the SEARCH GROUP types is possible,
// but need to be evaluated first. Otherwise, there is a risk of the app
// crashing.
type SEARCHGROUPINFO = {
  groupId: string;
  model: string;
  groupData: {
    clinicName: string;
    priceGroup: string;
    country: string;
    city: string;
    SFHomeDeliveryOnly: boolean;
    industry: string;
    salesOrg: string;
    altID: string;
    payer: string;
    archived: boolean;
    salesZone: string;
    clinicType: string;
    customerClass: string;
    billTo: string;
    softClosed: boolean;
    state: string;
    billingZip: string;
    zip: string;
    companyCode: string;
    soldToClinicName: string;
    soldTo: string;
    customerGroup: string;
    customerPlanGroup: string;
    salesTerritory: string;
    accountType: string;
    approvedEmails: string;
    streetAddress: string;
    blockedClinic: boolean;
    paymentTerm: string;
    billingCity: string;
    shipTo: string;
    salesRegion: string;
    customerPriceProcedure: string;
  };
  created: string;
  lastUpdated: string;
  createdTimestamp: number;
  lastUpdatedTimestamp: number;
  membersCount: number;
};

type SEARCHGROUP = {
  UID: string;
  groupId: string;
  model: string;
  relationshipData: {
    role: string;
    status: string;
  };
  profile: {
    lastName: string;
    country: string;
    locale: string;
    email: string;
    zip: string;
    firstName: string;
  };
  memberSince: string;
  lastUpdated: string;
  memberSinceTimestamp: number;
  lastUpdatedTimestamp: number;
  permissions: string;
};

class App extends Component<
  WithTranslation & WithRouterProps,
  {
    memberClinics: Array<GroupInfo<GigyaGroupModels.ClinicModel>>;
    selectedTab: number;
    selectedClinicForm?: GroupInfo<GigyaGroupModels.ClinicModel>;
    selectedClinicFormTW?: SEARCHGROUP;
    selectedClinicIndex?: number;
    dirty: boolean;
    modalDialog: ModalDialogState;
    user: GroupMember;
    searchTermClinics: string;
    searchGroupsResult: Array<SEARCHGROUP>;
    searchGroupInfoResult: SEARCHGROUPINFO;
  }
> {
  constructor(props: WithTranslation<undefined, undefined> & WithRouterProps) {
    super(props);
    this.state = {
      memberClinics: [],
      selectedTab: 0,
      selectedClinicForm: undefined,
      selectedClinicFormTW: undefined,
      selectedClinicIndex: undefined,
      dirty: false,
      modalDialog: {
        open: false,
      },
      user: {
        UID: "",
        groupId: "",
        model: "",
        lastUpdated: "",
        lastUpdatedTimestamp: "",
        memberSince: "",
        memberSinceTimestamp: "",
        permissions: "",
      },
      searchTermClinics: "",
      searchGroupsResult: [],
      searchGroupInfoResult: {
        groupId: "",
        model: "",
        groupData: {
          clinicName: "",
          priceGroup: "",
          country: "",
          city: "",
          SFHomeDeliveryOnly: false,
          industry: "",
          salesOrg: "",
          altID: "",
          payer: "",
          archived: false,
          salesZone: "",
          clinicType: "",
          customerClass: "",
          billTo: "",
          softClosed: false,
          state: "",
          billingZip: "",
          zip: "",
          companyCode: "",
          soldToClinicName: "",
          soldTo: "",
          customerGroup: "",
          customerPlanGroup: "",
          salesTerritory: "",
          accountType: "",
          approvedEmails: "",
          streetAddress: "",
          blockedClinic: false,
          paymentTerm: "",
          billingCity: "",
          shipTo: "",
          salesRegion: "",
          customerPriceProcedure: "",
        },
        created: "",
        lastUpdated: "",
        createdTimestamp: 0,
        lastUpdatedTimestamp: 0,
        membersCount: 0,
      },
    };
  }

  componentDidMount() {
    // Redirect the user if a session can not be found. We are not using react-router-dom
    // Here because that library only handles in app navigation and not navigation to
    // External URLs.
    window.gigya
      .hasSession()
      .then((sessionExist) => {
        if (!sessionExist) {
          console.log("Session does not exist...");
          window.location.href = getRedirectUrl();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // Get group data needed for the application
    window.gigya.accounts.groups.getAllMemberGroups({
      callback: (groupsResponse) => {
        if (!groupsResponse.errorCode) {
          // Get info of the logged in user. The initial groupId form the parent request is needed
          // So we are nesting this request in the callback method.
          if (groupsResponse.results.length) {
            window.gigya.accounts.groups.getGroupMemberInfo({
              model: GigyaGroupModels.ClinicModel,
              groupId: groupsResponse.results[0].groupId,
              callback: (infoResponse) => {
                if (infoResponse.errorCode) {
                  console.log(
                    "ERROR accounts.groups.getGroupMemberInfo: ",
                    infoResponse,
                  );
                } else {
                  if (process.env.REACT_APP_LOCALE !== "enUS") {
                    // SEARCH GROUPS TW ONLY
                    window.gigya.accounts.getJWT({
                      UID: infoResponse.result.UID,
                      callback: (resp) => {
                        if (resp["errorCode"] === 0) {
                          const headers = {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${resp.id_token}`,
                          };
                          const apiKey =
                            process.env.REACT_APP_CDC_API_KEY ?? "";
                          const searchClinicsUrlTW = `${process.env.REACT_APP_CDC_API_BASEURL}/v2/cdc/groups/searchClinics`;

                          axios
                            .post(
                              searchClinicsUrlTW,
                              {
                                apiKey,
                                getAll: true,
                              },
                              { headers },
                            )
                            .then((searchGroupsResponse) => {
                              window.gigya.accounts.getJWT({
                                UID: infoResponse.result.UID,
                                callback: (resp) => {
                                  if (resp["errorCode"] === 0) {
                                    const headers = {
                                      "Content-Type": "application/json",
                                      Authorization: `Bearer ${resp.id_token}`,
                                    };
                                    const apiKey =
                                      process.env.REACT_APP_CDC_API_KEY ?? "";
                                    const GetMembersUrlTW = `${process.env.REACT_APP_CDC_API_BASEURL}/v2/cdc/groups/searchClinics`;

                                    axios
                                      .post(
                                        GetMembersUrlTW,
                                        {
                                          apiKey,
                                          condition: "AND",
                                          clinicID: [
                                            {
                                              operator: "contains",
                                              lowerboundry:
                                                searchGroupsResponse.data
                                                  .results[0].groupId,
                                            },
                                          ],
                                        },
                                        { headers },
                                      )
                                      .then((searchClinicsResponse) => {
                                        // The initial state of the application is set here. Structured clone does a deep copy of an object
                                        // And moves the reference of any nested objects to the new copy.
                                        this.setState({
                                          memberClinics: groupsResponse.results,
                                          selectedClinicForm: groupsResponse
                                            .results.length
                                            ? structuredClone(
                                                groupsResponse.results[0],
                                              )
                                            : undefined,
                                          selectedClinicFormTW:
                                            searchGroupsResponse.data.results
                                              .length
                                              ? structuredClone(
                                                  searchGroupsResponse.data
                                                    .results[0],
                                                )
                                              : undefined,
                                          selectedClinicIndex: groupsResponse
                                            .results.length
                                            ? 0
                                            : undefined,
                                          user: infoResponse.result,
                                          searchGroupsResult:
                                            searchGroupsResponse.data.results,
                                          searchGroupInfoResult:
                                            searchClinicsResponse.data
                                              .results[0],
                                        });
                                      })
                                      .catch((err) => {
                                        console.log(
                                          "SEARCH CLINICS INFO ERR: ",
                                          err,
                                        );
                                      });
                                  }
                                },
                              });
                            })
                            .catch((err) => {
                              console.log("SEARCH CLINICS ERR: ", err);
                            });
                        }
                      },
                    });
                  } else {
                    this.setState({
                      memberClinics: groupsResponse.results,
                      selectedClinicForm: groupsResponse.results.length
                        ? structuredClone(groupsResponse.results[0])
                        : undefined,
                      selectedClinicIndex: groupsResponse.results.length
                        ? 0
                        : undefined,
                      user: infoResponse.result,
                    });
                  }
                }
              },
            });
          } else {
            console.log(
              "ERROR accounts.groups.getGroupMemberInfo: This user is not assigned to any group.",
            );
          }

          if (window.location.pathname === "/invite") {
            const groupId = Buffer.from(
              new URLSearchParams(window.location.search).get("clinic") ?? "",
              "base64",
            ).toString("utf8");
            if (groupId) {
              const groupName =
                (groupsResponse.results.find((g) => g.groupId === groupId)
                  ?.groupData.clinicName as string) ?? "";
              if (groupName) {
                this.setState({
                  modalDialog: {
                    open: true,
                    style: { height: "290px" },
                    title: this.props.t(
                      "exported.You’ve Been Added To A New Team!",
                    ),
                    content: (
                      <Typography variant="UIObjectsText1">
                        {this.props.t(
                          "exported. Congrats, you’re now a part of the College Blvd Animal Hospital team! If you did not intend to join this Team, you can Leave at any time. ",
                        )}
                      </Typography>
                    ),
                    actions: [
                      <Button
                        key={0}
                        variant="text"
                        onClick={this.handleModalDialogClose}
                      >
                        {this.props.t("exported.Got it!")}
                      </Button>,
                    ],
                  },
                });
              }
            }
          }
        }
      },
    });
  }

  updateDirty = (d: boolean) => {
    this.setState({ dirty: d });
  };

  refreshData = () => {
    const { memberClinics, searchGroupsResult } = this.state;

    if (process.env.REACT_APP_LOCALE !== "enUS") {
      searchGroupsResult[this.state.selectedClinicIndex!] = structuredClone(
        this.state.selectedClinicFormTW!,
      );
      this.setState({
        searchGroupsResult,
        dirty: false,
      });
    } else {
      memberClinics[this.state.selectedClinicIndex!] = structuredClone(
        this.state.selectedClinicForm!,
      );

      this.setState({
        memberClinics,
        dirty: false,
      });
    }
  };

  handleModalDialogClose = () => {
    this.setState({ modalDialog: { open: false } });
  };

  removeData = () => {
    const clinic = this.state?.memberClinics.filter(
      (item) => item.groupId != this.state?.selectedClinicForm?.groupId,
    );

    const clinicsTW = this.state?.searchGroupsResult.filter(
      (item) => item.groupId != this.state?.selectedClinicFormTW?.groupId,
    );

    this.setState({
      memberClinics: clinic,
      selectedClinicForm: clinic.length
        ? structuredClone(clinic[0])
        : undefined,
      selectedClinicFormTW: clinicsTW.length
        ? structuredClone(clinicsTW[0])
        : undefined,
      selectedClinicIndex: clinic.length ? 0 : undefined,
      searchGroupsResult: clinicsTW,
    });
  };

  handleSearchFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchStr = event.target.value;
    this.setState({ searchTermClinics: searchStr });
  };

  filterClinics = (clinic: GroupInfo<GigyaGroupModels.ClinicModel>) => {
    const dropDownValue =
      clinic.relationshipData.nickname ?? clinic.groupData.clinicName;
    return dropDownValue
      .toString()
      .toLowerCase()
      .includes(this.state.searchTermClinics.toLocaleLowerCase());
  };

  filterClinicsTW = (clinic: SEARCHGROUP) => {
    const dropDownValue = clinic.groupId;
    return dropDownValue
      .toString()
      .toLowerCase()
      .includes(this.state.searchTermClinics.toLocaleLowerCase());
  };

  displayTabMembers = () => {
    if (process.env.REACT_APP_LOCALE !== "enUS") {
      return (this.state.selectedClinicFormTW?.relationshipData.role ===
        "admin" ||
        this.state.selectedClinicFormTW?.relationshipData.role ===
          "employee") &&
        this.state.selectedClinicFormTW.relationshipData.status === "approved"
        ? "block"
        : "none";
    }

    return this.state.selectedClinicForm?.relationshipData.role === "admin" ||
      this.state.selectedClinicForm?.relationshipData.role === "employee"
      ? "block"
      : "none";
  };

  getDropDownItems = () => {
    if (process.env.REACT_APP_LOCALE !== "enUS") {
      return this.state?.searchGroupsResult
        ?.map((clinic, index) => ({
          ...clinic,
          indexOrig: index,
        }))
        ?.filter(this.filterClinicsTW)
        .map((c, i) => ({
          value: i,
          label: c.groupId,
          indexOrig: c.indexOrig,
        }));
    }

    return this.state?.memberClinics
      ?.map((clinic, index) => ({
        ...clinic,
        indexOrig: index,
      }))
      ?.filter(this.filterClinics)
      .map((c, i) => ({
        value: i,
        label: (c.relationshipData.nickname ??
          c.groupData.clinicName) as string,
        indexOrig: c.indexOrig,
      }));
  };

  handleDropDownChange = (value: unknown) => {
    if (process.env.REACT_APP_LOCALE !== "enUS") {
      window.gigya.accounts.getJWT({
        UID: this.state.user.UID,
        callback: (resp) => {
          if (resp["errorCode"] === 0) {
            const headers = {
              "Content-Type": "application/json",
              Authorization: `Bearer ${resp.id_token}`,
            };
            const apiKey = process.env.REACT_APP_CDC_API_KEY ?? "";
            const GetMembersUrlTW = `${process.env.REACT_APP_CDC_API_BASEURL}/v2/cdc/groups/searchClinics`;

            axios
              .post(
                GetMembersUrlTW,
                {
                  apiKey,
                  condition: "AND",
                  clinicID: [
                    {
                      operator: "contains",
                      lowerboundry:
                        this.state.searchGroupsResult[Number(value)].groupId,
                    },
                  ],
                },
                { headers },
              )
              .then((searchClinicsResponse) => {
                // The initial state of the application is set here. Structured clone does a deep copy of an object
                // And moves the reference of any nested objects to the new copy.
                this.setState({
                  searchGroupInfoResult: {
                    ...searchClinicsResponse.data.results[0],
                  },
                  selectedClinicFormTW: structuredClone(
                    this.state?.searchGroupsResult[Number(value)],
                  ),
                  selectedClinicIndex: Number(value),
                  selectedTab: isMemberAdmin(
                    this.state?.searchGroupsResult[Number(value)].permissions,
                  )
                    ? this.state.selectedTab
                    : 0,
                });
              })
              .catch((err) => {
                console.log("SEARCH GROUP INFO ERR: ", err);
              });
          }
        },
      });
    } else {
      this.setState({
        selectedClinicForm: structuredClone(
          this.state?.memberClinics[Number(value)],
        ),
        selectedClinicIndex: Number(value),
        selectedTab: isMemberAdmin(
          this.state?.memberClinics[Number(value)].permissions,
        )
          ? this.state.selectedTab
          : 0,
      });
    }
  };

  render() {
    // Return what you want displayed while fetching data here
    if (this.state === undefined || this.state === null) {
      return <></>;
    }

    // If the user is not assigned to a clinic, show the empty page
    if (
      process.env.REACT_APP_LOCALE !== "enUS" &&
      this.state.searchGroupsResult.length === 0
    ) {
      // Update user_type to reflect the kind of user that is logged in.
      // This is being done at the parent level to avoid having to pass
      // additional props to the child component.
      const UPDATED_EMPTY_MEMBERS = {
        ...EMPTY_MEMBERS,
        user_type: "admin",
      };
      pushDataLayerEvent(UPDATED_EMPTY_MEMBERS);

      return <Empty />;
    }
    if (this.state.memberClinics.length === 0) {
      // Update user_type to reflect the kind of user that is logged in.
      // This is being done at the parent level to avoid having to pass
      // additional props to the child component.
      const UPDATED_EMPTY_MEMBERS = {
        ...EMPTY_MEMBERS,
        user_type: "admin",
      };
      pushDataLayerEvent(UPDATED_EMPTY_MEMBERS);

      return <Empty />;
    }

    // Display the application here
    return [
      React.cloneElement(
        transformExportedDesign(
          {
            tabs: (
              <Tabs
                value={this.state?.selectedTab ?? 0}
                onChange={(_: React.SyntheticEvent, v: number) => {
                  this.setState({
                    selectedTab: v,
                  });
                }}
              />
            ),
            tab: <Tab disabled={Boolean(this.state?.dirty)} />,
            tab1: (
              <Tab
                style={{
                  display: this.displayTabMembers(),
                }}
                disabled={
                  process.env.REACT_APP_LOCALE !== "enUS"
                    ? !isMemberAdmin(
                        this.state?.selectedClinicFormTW?.permissions,
                      )
                    : !isMemberAdmin(
                        this.state?.selectedClinicForm?.permissions,
                      )
                }
              />
            ),
            "text-field1on-white1single-linewith-icona-inactive-copy": (
              <SelectW
                disabled={Boolean(this.state?.dirty)}
                skeleton={
                  !this.state?.memberClinics || !this.state?.searchGroupsResult
                }
                label={this.props.t("exported.Selected Team")}
                value={() =>
                  this.state?.selectedClinicIndex ??
                  "You are not assigned to any group"
                }
                items={this.getDropDownItems()}
                onChange={this.handleDropDownChange}
                search
                searchLabel="Search Clinics"
                onSearchChange={this.handleSearchFilter}
                onClose={() => this.setState({ searchTermClinics: "" })}
              />
            ),
            // 'frame-button2': <Button disabled={Boolean(this.state?.dirty)} />,
            "frame-button2": "removeAll",
            frame51: [
              process.env.REACT_APP_LOCALE !== "enUS" ? (
                <GroupDetailsTW
                  key={0}
                  clinicInfo={this.state?.selectedClinicFormTW}
                  clinicName={
                    this.state.searchGroupInfoResult.groupData.clinicName
                  }
                  user={this.state?.user}
                  onUpdate={this.refreshData}
                  onRemoval={this.removeData}
                />
              ) : (
                <GroupDetails
                  key={0}
                  clinicInfo={this.state?.selectedClinicForm}
                  user={this.state?.user}
                  onUpdate={this.refreshData}
                  onRemoval={this.removeData}
                />
              ),
              <GroupMembers
                key={1}
                clinicInfo={this.state?.selectedClinicForm}
                clinicInfoTW={this.state?.selectedClinicFormTW}
                clinicName={
                  this.state.searchGroupInfoResult.groupData.clinicName
                }
                user={this.state?.user}
                onDirty={this.updateDirty}
              />,
            ][this.state?.selectedTab ?? 0],
            frame334: "removeAll",
          },
          P11440311HGMHomeAdminmemberViewgroupdetailstabclon(),
        ),
        { key: 0 },
      ), // eslint-disable-line new-cap
      <ModalDialog
        key={1}
        open={this.state?.modalDialog?.open ?? false}
        onClose={this.handleModalDialogClose}
        style={this.state?.modalDialog?.style}
        title={this.state?.modalDialog?.title ?? ""}
        content={this.state?.modalDialog?.content ?? []}
        actions={this.state?.modalDialog?.actions ?? []}
      />,
    ];
  }
}

export default withTranslation()(withRouter(App));

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type ToastState = {
  open: boolean;
  severity?: AlertColor;
  message?: string;
  errorDetails?: string;
};

export type ModalDialogState = {
  open: boolean;
  title?: string;
  content?: JSX.Element | JSX.Element[];
  actions?: JSX.Element[];
  style?: React.CSSProperties;
};

export const objectMerge = (
  s: Record<string, unknown>,
  d: Record<string, unknown>,
) => {
  Object.keys(s).forEach((k) => {
    if (s[k] && typeof s[k] === "object") {
      d[k] = d[k] ?? {};
      objectMerge(
        s[k] as Record<string, unknown>,
        d[k] as Record<string, unknown>,
      );
    } else {
      d[k] = s[k];
    }
  });
};
